<script setup>

    import { useRoute, useRouter } from 'vue-router/composables'
    import { onMounted, reactive, ref, toRefs } from 'vue'
    import {spuDetail} from '@/api/index'
    import moment from 'moment'
    const route = useRoute()
    const query = route.query ||{}

    const state = reactive({
        detail:{}
    })

    const {detail} = toRefs(state)
    console.log(query,'2');

    onMounted(() => {
        // 获取详情
        spuDetail(query).then(({data})=>{
            console.log(data,999)
            state.detail = data
        })

    })



</script>

<template>
    <div class="box">
        <el-breadcrumb separator-class="el-icon-d-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/cultural-creative' }">文创</el-breadcrumb-item>
            <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="info-detail flex">
            <el-image
                style="width: 400px;"
                :src="detail.picUrl"
                :preview-src-list="[detail.picUrl]">
            </el-image>
            <div class="info-detail-desc">
                <h3>{{detail.name}}</h3>
                <p>提示:文创产品均为线上展示线下购买</p>
                <h4>产品介绍</h4>
                <div class="info-detail-text">
                    <div v-html="detail.description"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped lang="scss">
    .box{
        padding: 44px 0;
        margin: auto;
        width: 1490px;

        ::v-deep .el-breadcrumb{
            color:#070707;
            font-size:18px;
        }
    }
    .info-detail{
        background: #ffffff;
        margin: 37px auto;
        padding: 30px;

        img{
            width:753px;
            height:960px;
        }
    }
    .info-detail-desc{
        h3{
            font-weight:700;
            color:#3b3b3b;
            font-size:41px;
            text-align: center;
            margin-top: 74px;
        }
        p{
            color:#8b3527;
            font-size:18px;
            margin-top: 20px;
            margin-bottom: 88px;
            text-align: center;
        }
        h4{
            font-weight:700;
            color:#3b3b3b;
            font-size:33px;
            padding: 0 102px;
        }
        .info-detail-text{
            color:#1c1c1c;
            font-size:16px;
            margin-top: 39px;
            padding: 0 102px;
            line-height: 30px;

            ::v-deep img{
                max-width: 100%;
            }
        }
    }
</style>
